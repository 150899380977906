import RichText from '@core/RichText';
import React from 'react';
import styled from 'styled-components';

import ReferHandMobile from '@illustration-files/refer-hand-mobile.svg';
import ReferHand from '@illustration-files/refer-hand.svg';
import ReferCouch from '@illustration-files/refer-couch.svg';
import mq, { mqdown } from '@mq';
import { grid } from '@mixins';

const ReferMemberSection = ({ title, text }) => (
  <Container>
    <ContainerInner>
      <IllustrationHandMobile src={ReferHandMobile} />
      <IllustrationHand src={ReferHand} />
      <TextContainer>
        <Title>{title}</Title>
        <Text text={text} />
      </TextContainer>
      <IllustrationCouch src={ReferCouch} />
    </ContainerInner>
  </Container>
);

const Container = styled.section``;

const ContainerInner = styled.div`
  grid-column: 1 / -1;
  background-color: var(--color-primary);
  border-radius: 1rem;
  display: grid;

  ${mqdown.small} {
    margin-right: calc(var(--gutter) / -2);
    margin-left: calc(var(--gutter) / -2);
    padding-right: calc(var(--gutter) / 2);
    grid-template-columns: 1fr 3fr;
    column-gap: var(--space-xxs);
  }

  ${mq.small} {
    grid-template-columns: 1fr 3fr 1fr;
    column-gap: var(--space-s);
    align-items: center;
  }

  ${mq.medium} {
    grid-column: 2 / -2;
  }

  ${mq.large} {
    grid-column: 2 / -2;
  }

  ${mq.huge} {
    grid-column: 5 / -5;
  }
`;

const IllustrationHandMobile = styled.img`
  align-self: center;

  ${mq.small} {
    display: none;
  }
`;

const IllustrationHand = styled.img`
  padding-top: var(--space-s);
  padding-bottom: var(--space-s);

  ${mqdown.small} {
    display: none;
  }
`;

const IllustrationCouch = styled.img`
  align-self: center;
  padding-right: var(--space-s);
  padding-top: var(--space-s);
  padding-bottom: var(--space-s);

  ${mqdown.small} {
    display: none;
  }
`;

const TextContainer = styled.div`
  padding-top: 1.25rem;
  padding-bottom: 1rem;
`;

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;

  ${mq.small} {
    font: var(--font-xm);
    text-align: center;
    font-weight: bold;
  }
`;

const Text = styled(RichText)`
  font-size: 1rem;

  ${mq.small} {
    font: var(--font-m);
    text-align: center;
  }

  span > span {
    font-weight: bold;
  }
`;

export default ReferMemberSection;
