import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { injectIntl } from 'gatsby-plugin-react-intl';

import mq, { mqdown } from '@mq';
import RichText from '@core/RichText';

import Seo from '@core/Seo';

import Sections from '@components/Sections';
import TextSliderSectionSmall from '@components/TextSliderSectionSmall';

import illustrations from '@common/illustrations';
import ContactSection from '@components/ContactSection';
import CheckSvg from '@icons/check.svg';
import ReferMemberSection from '@components/ReferMemberSection';

const DeskIllustration = illustrations.desks;

const DenizenEiswerkPage = ({
  data: {
    contentfulDenizenEiswerkPage: {
      title,
      description,
      sections,
      details,
      amenitiesTitle,
      amenitiesText,
      textSliderTitle,
      textSlider,
      referMemberTitle,
      referMemberText,
      contactName,
      contactTitle,
      contactInfo,
      contactImage,
    },
  },
}) => (
  <>
    <Seo
      pageTitle={title}
      pageDescription="Located on the ground floor of the historic Eiswerk on Köpenicker Strasse in Kreuzberg, Denizen House is a neighbourhood coworking space that that offers a range of informal workspaces as well as on-site refreshment and recreational facilities."
    />
    <main>
      <TopSection>
        <Title>{title}</Title>
        <Description text={description} />
      </TopSection>

      <Sections sections={sections} />
      <DetailsSection>
        <Details>
          {details.map(detail => (
            <li key={detail.id}>
              <Check />
              <DetailKey>{detail.key}</DetailKey>
              <DetailValue>{detail.value}</DetailValue>
            </li>
          ))}
          <li>
            <Check />
            <DetailKey>{amenitiesTitle}</DetailKey>
            <DetailValue>
              <RichText text={amenitiesText} />
            </DetailValue>
          </li>
        </Details>
      </DetailsSection>

      <TextSliderSectionSmall
        title={textSliderTitle}
        items={textSlider}
        illustration={DeskIllustration}
        context="needspace-coworkingberlin-denizeneiswerk"
      />
      <ReferMemberSection title={referMemberTitle} text={referMemberText} />
      <ContactSection
        contactName={contactName}
        contactTitle={contactTitle}
        contactInfo={contactInfo}
        contactImage={contactImage}
      />
    </main>
  </>
);

const defaultTextColumns = css`
  grid-column: 1 / -1;

  ${mq.medium} {
    grid-column: 2 / -2;
  }

  ${mq.large} {
    grid-column: 3 / -3;
  }

  ${mq.huge} {
    grid-column: 6 / -6;
  }
`;

const spaceColumns = css`
  ${mqdown.small} {
    grid-column: 1 / -1;
  }

  ${mq.small} {
    grid-column-end: span 3;
    margin-left: var(--space-xs);
    margin-right: var(--space-xs);
  }

  ${mq.medium} {
    grid-column-end: span 6;
    margin-left: var(--space-s);
    margin-right: var(--space-s);
  }
`;

const TopSection = styled.section`
  padding-bottom: 0;

  ${mqdown.small} {
    &:first-child {
      padding-top: var(--space-xs);
    }
  }
`;

const Title = styled.h1`
  ${defaultTextColumns};
  margin-bottom: var(--space-xs);

  ${mq.small} {
    margin-bottom: var(--space-s);
  }
`;

const Description = styled(RichText)`
  ${defaultTextColumns};
`;

const DetailsSection = styled.section`
  ${mqdown.small} {
    padding-top: 0;
    margin-top: calc(-1 * var(--space-xxs));
  }

  ${mq.small} {
    padding-bottom: var(--space-xs);
    padding-top: var(--space-s);
  }
`;

const Details = styled.ul`
  ${defaultTextColumns};
  display: grid;
  margin-bottom: var(--space-s);

  ul {
    margin: 0;
  }

  ${mqdown.small} {
    row-gap: var(--space-xxs);
  }

  ${mq.small} {
    row-gap: var(--space-s);
    align-content: start;
  }

  li {
    ${mq.small} {
      display: grid;
      column-gap: var(--space-xs);
      row-gap: var(--space-xxs);
      justify-content: start;
    }
  }
`;

const DetailKey = styled.div`
  ${mqdown.small} {
    color: var(--color-grey);
    padding-top: var(--space-xxs);
    margin-top: var(--space-xxs);
    border-top: 0.0625rem solid var(--color-secondary);
    margin-bottom: var(--space-xxxs);
  }

  ${mq.small} {
    font-weight: bold;
    margin-right: 0.5ch;
    grid-column: 2;
    grid-row: 1;
  }
`;

const DetailValue = styled.div`
  ${mq.small} {
    grid-column: 2;
    grid-row: 2;
  }
`;

const Check = styled(CheckSvg)`
  ${mqdown.small} {
    display: none;
  }

  ${mq.small} {
    width: 2.375rem;
    height: 2.375rem;

    grid-column: 1;
    grid-row: 1;
    align-self: center;
  }
`;

export const denizenEiswerkPageQuery = graphql`
  query DenizenEiswerkPageQuery($locale: String) {
    contentfulDenizenEiswerkPage(node_locale: { eq: $locale }) {
      node_locale
      title
      description {
        raw
      }
      details {
        key
        value
        id
      }
      amenitiesTitle
      amenitiesText {
        raw
      }
      textSliderTitle
      textSlider {
        id
        text
      }
      referMemberTitle
      referMemberText {
        raw
      }
      contactName
      contactTitle
      contactInfo {
        raw
      }
      sections {
        ... on ContentfulEntry {
          ...sections
        }
      }
      contactImage {
        description
        id
        gatsbyImageData(
          placeholder: DOMINANT_COLOR
          breakpoints: [375, 560, 750]
          formats: [AUTO, WEBP, AVIF]
          aspectRatio: 1
          resizingBehavior: FILL
          cropFocus: FACES
          quality: 75
        )
      }
    }
  }
`;

export default injectIntl(DenizenEiswerkPage);
